@use 'theme';
@use 'breakpoints';
@use 'display';

.wrapper {
  display: flex;
  align-items: center;
  gap: theme.spacing(1);

  .hideOnMobile {
    &, &:disabled {
      @include display.hideOnMobile(flex);

    }
  }
  
  .hideOnDesktop {
    &, &:disabled {
      @include display.hideOnDesktop(flex);
    }
  }
}
