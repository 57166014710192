@use 'transitions';

.wrapper {
  width: 100%;
}

.contentWrapper {
  height: var(--collapsibleHeight);
  overflow: hidden;
  transition: transitions.get('height', 200ms);
}