@use 'theme';
@use 'fonts';
@use 'borders';
@use 'breakpoints';
@use 'baseline';

.wrapper {
  align-items: center;
  display: flex;
  font-size: fonts.size('xSmall');
}

.numbers {
  display: flex;
  margin-right: theme.spacing(2);
}

.number {
  @include baseline.reset-button;
  border: borders.get($color: theme.get('darkDividerColor'));
  border-radius: borders.radius('sm');
  color: theme.get('textColor');
  cursor: pointer;
  margin-right: theme.spacing(1);
  padding: theme.spacing(0.625);

  &:hover {
    border-color: theme.get('darkerActiveColor');
    color: theme.get('darkerActiveColor');
  }

  &.isSelected {
    border-color: theme.get('darkerActiveColor');
    color: theme.get('darkerActiveColor');
  }
}

.resultsPerPageInfo {
  display: none;

  @include breakpoints.up('small') {
    display: block;
  }
}
