@use 'theme';
@use 'borders';
@use 'transitions';

.wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: theme.spacing(0, 1.5);
  cursor: pointer;
}

.input {
  display: none;
}

.disabledWrapper {
  pointer-events: none;
  .disabled {
    opacity: 0.3;
  }
}

.label {
  &:empty {
    display: none;
  }
}

.labelWrapper {
  line-height: 18px;
  align-items: center;
}

.error {
  color: theme.palette('error');
  margin-top: theme.spacing(1);
}