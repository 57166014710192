@use 'theme';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: theme.spacing(1);
  margin: theme.spacing(10, auto);
}

.link {
  cursor: pointer;
}
