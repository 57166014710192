@use 'theme';
@use 'fonts';
@use 'borders';
@use 'breakpoints';
@use 'baseline';
@use 'shadows';

.container {
  display: flex;
  align-items: flex-start;
  height: 24px;
}

.wrapper {
  @include baseline.reset-button;
  margin-top: theme.spacing(-2);
  padding: theme.spacing(2);
  background-color: theme.palette('white');
  border-radius: borders.radius('md');
  z-index: 9;
}

.wrapperWithShadow {
  box-shadow: shadows.get('default');
}

.list {
  width: 100%;
  height: 100%;
  margin: theme.spacing(0);
  padding-left: theme.spacing(0);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
  user-select: none;
  color: theme.get('textColor');
  font-size: fonts.size('xSmall');
}

.activeItem {
  width: 100%;
  margin: theme.spacing(0);
  display: flex;
  align-items: center;
  color: theme.get('textColor');
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  font-size: fonts.size('xSmall');

  &:hover {
    text-decoration: none;
    color: theme.get('textColor');
    cursor: pointer;
  }

  & .activeItemContainer {
    display: flex;
    align-items: center;
  
    & .activeItemSort {
      display: block;
      margin-right: theme.spacing(0.5);
      color: rgba(theme.get('textColorRgb'), 0.65);
    }
  }
}

.iconWrapper {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 7px;
  width: 4px;
  transform: rotate(90deg);

  &.openedIcon {
    transform: rotate(-90deg);
  }
}

.listItem {
  height: 24px;
  width: 100%;
  margin: theme.spacing(2, 0, 0);
  display: flex;
  align-items: center;
  color: theme.get('textColor');
  cursor: pointer;
  font-size: fonts.size('xSmall');
}

.link {
  display: none;
}

.visibleLink {
  display: block;
}
