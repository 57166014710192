@use 'theme';
@use 'fonts';
@use 'breakpoints';
@use 'display';

.products {
  display: flex;
  flex-wrap: wrap;
  background-color: theme.get('secondaryBackgroundColor');
  margin: theme.spacing(0, -2);

  @include breakpoints.up('small') {
    background-color: transparent;
    margin: theme.spacing(0);
  }
}

.listMode {
  display: flex;
}

.compactMode {
  display: flex;
}

.card {
  display: flex;
  width: 100%;
}

.cardFade {
  animation: fade 0.2s ease;
}

.link {
  width: 100%;
}

.productCardWrapper {
  display: flex;
  width: 100%;
  min-width: 261px;

  .listMode & {
    @include breakpoints.up('small') {
      min-height: calc(82px + var(--additionalHeight, 0));
    }
  }

  .tileMode & {
    min-height: calc(357px + var(--additionalHeight, 0));

    @include breakpoints.up('small') {
      width: min-content;
    }
  }

  .compactMode & {
    min-height: 150px;

    @include breakpoints.up('small') {
      min-height: 66px;
    }
  }
}

.placeholderLink {
  position: relative;
  background-color: theme.palette('white');
  display: block;
  width: 100%;
  height: calc(100% - theme.spacing(1));
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .compactMode & {
    height: 100%;
    padding: theme.spacing(1.5);
  }
}

.placeholderText {
  position: absolute;
  top: 213px;
  left: 24px;
  color: theme.get('textColor');
  font-size: fonts.size('small');
  line-height: 20px;

  .compactMode & {
    top: 16px;
    left: 80px;
  }

  @include breakpoints.up('small') {
    .tileMode & {
      left: 16px;
    }

    .listMode & {
      top: 53px;
      left: 284px;
    }

    .compactMode & {
      top: 13px;
      left: 76px;
    }

    .compactWithAttributes & {
      top: 23px;
      left: 76px;
    }
  }
}

.headerWrapper {
  width: 100%;
  padding: theme.spacing(1.5);
  gap: theme.spacing(2);

  @include display.hideOnMobile(flex);

  .headerModel {
    width: 233px;
  }

  .headerPrice {
    width: 159px;
    padding-right: theme.spacing(7);
    text-align: right;

    @include breakpoints.up('medium') {
      width: 251px;
      padding-right: theme.spacing(15.375);
    }
  }
}

.attributes {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

.attribute {
  display: flex;
  align-items: center;

  @include breakpoints.up('small') {
    width: var(--attributeWidth);
    padding: theme.spacing(0, 0.5);
  }
}
