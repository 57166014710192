@use 'theme';
@use 'breakpoints';
@use 'borders';
@use 'display';

.buttons {
  display: flex;
  gap: theme.spacing(2.5, 4);
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  
  @include breakpoints.up('small') {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
}

.buttonsSection {
  display: flex;
  align-items: center;
  gap: theme.spacing(2, 4);

  .showMoreFiltersButton {
    width: 132px;
    white-space: nowrap;
    color: theme.palette('white');
    padding: theme.spacing(1.125, 2);
  
    &.active {
      color: theme.get('darkActiveColor');
      border-radius: borders.radius('xMd', 'xMd', 0, 0);
      background-color: theme.get('backgroundColor');
  
      &:hover {
        background-color: theme.get('darkActiveColor');
        color: theme.palette('white');
      }
    }
  }
}

.additionalFilters {
  @extend .buttonsSection;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  @include breakpoints.up('small') {
    align-items: flex-end;
  }

  @include breakpoints.up('largeMedium') {
    flex-direction: row;
  }
}

.switchers {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: theme.spacing(2, 4);

  @include breakpoints.up('small') {
    flex-direction: row;
  }
}

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: theme.spacing(1);

  @include breakpoints.up('small') {
    flex-direction: row;
  }
}

.sort {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
}

.search {
  width: 100%;
}

.button {
  display: flex;
  gap: theme.spacing(0.5);
  cursor: pointer;
}

.expandArrow {
  margin-top: theme.spacing(0.5);
}

.divider {
  height: 25px;
  border-color: theme.get('dividerColor');
  @include display.hideOnMobile();
}
