@use 'theme';
@use 'borders';
@use 'breakpoints';
@use 'transitions';

.triggerWrapper {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.trigger {
  display: flex;
  align-items: center;
  background: theme.get('backgroundColor');
  padding: theme.spacing(2, 0, 2, 2);
  border-radius: borders.radius('sm');
  flex: 1 1 auto;
  width: calc(100% - 70px);

  @include breakpoints.up('medium') {
    padding: theme.spacing(3);
  }
}

.arrowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: theme.get('backgroundColor');
  min-width: 48px;

  @include breakpoints.up('medium') {
    background: theme.palette('white');
    min-width: 70px;
  }
}

.arrow {
  width: 10px;
  height: 10px;
  border-left: borders.get($color: theme.get('darkDividerColor'));
  border-bottom: borders.get($color: theme.get('darkDividerColor'));
  margin-top: theme.spacing(-0.5);
  transform: rotate(-45deg);
  transition: transitions.get(('transform', 'margin-top'), 200ms),
}

.arrowOpened {
  margin-top: theme.spacing(0.5);
  transform: rotate(135deg);
}