@use 'theme';
@use 'breakpoints';
@use 'display';

.link {
  display: flex;
  white-space: nowrap;
}

.full {
  @include display.except('small', 'largeMedium');
}

.compact {
  @include display.between('small', 'largeMedium');
}

.checkbox {
  flex-direction: row-reverse;

  @include breakpoints.up('small') {
    flex-direction: row;
  }
}
