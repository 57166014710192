@use 'transitions';

@layer utilities {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--wrapperWidth);
  }
  
  .icon {
    width: var(--iconWidth);
    transform: rotate(90deg);
    transition: transitions.get('transform');
  }
  
  .opened {
    transform: rotate(270deg);
  }
}
