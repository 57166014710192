@use 'theme';
@use 'baseline';
@use 'display';
@use 'transitions';

.listVariantButton {
  @include baseline.reset-button;
  max-height: 40px;
  color: rgba(theme.get('textColorRgb'), 0.9);
  transition: transitions.get(color);
  
  &:hover {
    color: theme.get('textColor');
  }
  
  &:disabled {
    display: inline-block;
    cursor: auto;
    color: theme.get('iconColor');

  }
}