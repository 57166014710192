@use 'theme';
@use 'transitions';

.sizeSmall {
  --switchWidth: 33px;
  --switchHeight: 20px;
  --dotSize: 16px;
  --switchMovement: 18px;
}

.sizeLarge {
  --switchWidth: 49px;
  --switchHeight: 29px;
  --dotSize: 25px;
  --switchMovement: 27px;
}

.wrapper {
  display: flex;
  position: relative;
  width: var(--switchWidth);
  height: var(--switchHeight);
  border-radius: 29px;
  padding: theme.spacing(0.25);
  background-color: rgba(theme.get('textColorRgb'), 0.3);
  transition: transitions.get(('background-color', 'opacity'), 200ms);
  cursor: pointer;

  &.active {
    background-color: theme.get('activeColor');
  
    & .dot {
      left: calc(100% - var(--switchMovement));
    }
  }
  
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.dot {
  position: absolute;
  left: 2px;
  width: var(--dotSize);
  height: var(--dotSize);
  border-radius: 50%;
  background-color: theme.palette('white');
  box-shadow: 0 0 1px rgba(theme.get('textColorRgb'), 0.4) inset;
  transition: transitions.get('left', 200ms);
}