@use 'theme';
@use 'borders';
@use 'baseline';
@use 'breakpoints';
@use 'transitions';
@use 'fonts';

.filter {
  display: flex;
  flex-direction: column;
  gap: theme.spacing(1);

  @include breakpoints.up('medium') {
    flex-direction: row;
  }
}

.name {
  min-width: 100px;
  margin: theme.spacing(auto, 0);
}

.values {
  display: flex;
  gap: theme.spacing(1);
  padding-bottom: theme.spacing(0.5);
  overflow-x: scroll;
}

.value {
  @include baseline.reset-button;
  padding: theme.spacing(1.75, 3);
  height: 48px;
  background-color: theme.palette('white');
  border-bottom: borders.get($width: 1px, $color: transparent);
  border-radius: borders.radius('md', 'md', 0, 0);
  color: theme.get('textColor');
  white-space: nowrap;
  cursor: pointer;
  transition: transitions.get(border-bottom);

  &:hover {
    border-bottom: borders.get($width: 1px, $color: theme.get('activeColor'));
  }

  &.isSelected {
    font-weight: fonts.weight('bold');
    padding-bottom: calc(theme.spacing(1.75) - 1px);
    border-bottom: borders.get($width: 2px, $color: theme.get('activeColor'));
  }

  .count {
    margin-left: theme.spacing(0.5);
    font-weight: fonts.weight('regular');
  }
}
