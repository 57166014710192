@use 'theme';
@use 'borders';
@use 'display';
@use 'breakpoints';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: theme.spacing(2);
}

.filtersContainer {
  margin-bottom: theme.spacing(3);
}

.filters {
  display: flex;
  flex-direction: column;
  gap: theme.spacing(1.5);
}

.filtersCollapse {
  display: flex;
  flex-direction: column;
}

.filtersContent {
  display: flex;
  flex-direction: column;
  gap: theme.spacing(1.5);
  padding: theme.spacing(3);
  background-color: theme.get('backgroundColor');
  border-radius: borders.radius('xMd');

  @include breakpoints.up('small') {
    border-radius: borders.radius(0, 'xMd', 'xMd', 'xMd');
  }
};

.filtersButtons {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: theme.spacing(2, 5);
  margin-top: theme.spacing(0.5);

  @include breakpoints.up('small') {
    flex-direction: row;
    align-items: center;
  }

  .filterButton {
    display: flex;
    align-items: center;
  
    svg {
      margin-left: theme.spacing(1);
    }
  }
}

.filtersBox {
  height: 12px;
  width: 133px;
  background-color: theme.get('backgroundColor');

  @include display.hideOnMobile();
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: theme.spacing(2, 1, 7);
  padding-right: theme.spacing(1);
}
